/* You can add global styles to this file, and also import other style files */

body {
  margin: 0;
  font-family: "Poppins", system-ui !important;
}

.delete-container {
  // width: 80%;
  width: 600px;
  // height: 85%;
  height: 700px;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.25);
  background-color: rgba(250, 250, 250, 1);
  border-radius: 16px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (width < 1400px) {
  .delete-container {
    width: 500px;
    height: 600px;
  }
}

@media (width < 1200px) {
  .delete-container {
    width: 450px;
    height: 550px;
  }
}

html, body { height: 100%; }
